.Main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    margin-top: 20px;
  }
  
  /* .Main_Full {
    height: 100vh;
  } */
  
  .Main_Headline {
    background-color: #9d00ff;
    font-weight: 400;
    font-size: 26px;
    color: black;
    align-self: center;
  }
  
  .Main_Headline span {
    color: white;
  }
  
  .Main_AdditionalInfo {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 30px;
    margin: 0;
    min-height: 35px;
    cursor: pointer;
    color: white;
    text-decoration: underline;
    text-align: center;
  }

  .Main_FooterAlternate {
    margin: 0;
    position: absolute;
    right: 10px;
  }

  .Main_FooterContract {
    margin: 0;
    position: absolute;
    left: 10px;
  }

  .Main_FooterAddress {
    margin: 0;
    cursor: pointer;
  }
  
  .Main_Footer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    margin: 0;
    min-height: 35px;
    width: 100%;
    background-color: white;
    color: black;
    text-align: center;
  }
  
  .Main_MintButton {
    background: white;
    position: relative;
    display: inline-block;
    overflow: hidden;
    border: none;
    padding: 15px 40px;
    -webkit-transition: background-color 200ms linear, color 200ms linear;
    transition: background-color 200ms linear, color 200ms linear;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    text-decoration: none;
    margin-top: 10px;
    cursor: pointer;
    align-self: center;
  }

  .Main_ConnectButton {
    background: white;
    position: relative;
    display: inline-block;
    overflow: hidden;
    border: none;
    padding: 15px 40px;
    -webkit-transition: background-color 200ms linear, color 200ms linear;
    transition: background-color 200ms linear, color 200ms linear;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    text-decoration: none;
    margin-top: 10px;
    cursor: pointer;
    align-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .Main_FetchButton {
    background: white;
    position: relative;
    display: inline-block;
    overflow: hidden;
    border: none;
    padding: 15px 40px;
    -webkit-transition: background-color 200ms linear, color 200ms linear;
    transition: background-color 200ms linear, color 200ms linear;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    text-decoration: none;
    margin-top: 10px;
    cursor: pointer;
    align-self: center;
    max-width: 75%;
  }
  
  .Main_MintButtonActive:hover {
    background-color: #9d00ff;
    color: #f2f3f5;
  }
  
  .Main_MintButtonDisabled {
    cursor:auto !important;
    animation: glow 2.5s ease-in-out infinite;
  }

  .Main_MintButtonHighlight {
    animation: glow 2.5s ease-in-out infinite;
  }
  
  @media screen and (max-width: 600px) {
    .Main_Footer {
        font-size: 10px;
    }
  }
  
  @keyframes glow {
    0% {
      background-color: white;
    }
    50% {
      background-color: #9d00ff;
    }
    100% {
        background-color: white;
    }
  }
  