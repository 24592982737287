@font-face {
  font-family: ChakraPetch;
  src: url('./assets/fonts/Chakra_Petch/ChakraPetch-Regular.ttf');
}

html {
  height: 100%;
}

body {
  margin: 20px;
  font-family: 'ChakraPetch', cursive, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #202125;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}