.UsedCheck {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #202125;
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.UsedCheck_Title {
    color: white;
}

.UsedCheck_Container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    z-index: 999 !important;
    flex-direction: column;
    align-items: center;
}

.UsedCheck_Container input {
    width: 35%;
    justify-content: center;
    background-color: white;
    z-index: 999 !important;
    height: 40px;
    font-size: 18px;
}

.UsedCheck_Result {
    margin-top: 20px;
    font-weight: bold;
    font-size: 18px;
}

.UsedCheck_CloseButton {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 75px;
    height: 35px;
    background-color: white;
    border: none;
}

@media only screen and (max-width: 800px) { 
    .UsedCheck_Container input {
        width: 90%;
    }
}