body {
  background-color: #202125 !important;
}

.Snapshot_Notice {
  color: white;
  font-size: 1.3rem;
  line-height: 1.5rem;
  text-align: center;
  font-family: 'ChakraPetch';
}

.Snapshot_Notice_Res {
  color: white;
  font-family: 'ChakraPetch';
  font-size: 1.4rem;
  margin-top: 40px;
  line-height: 40px;
  text-align: center;
}

.Snapshot_Notice_Res span {
  color: yellow;
  font-weight: bold;
}

.Snapshot_Notice_Res p {
  word-break: break-word;
}

.Snapshot {
  text-align: center;
}

.Snapshot_MainSection {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Snapshot_Logo {
  /* animation: float 6s ease-in-out infinite; */
  margin-bottom: 20px;
  height: 80px;
}

.Snapshot_Input {
  margin: 0 auto;
  margin-top: 50px;
  width: 500px;
  height: 35px;
  font-family: 'ChakraPetch';
  text-align: center;
  font-size: 1.3rem;
}

.Snapshot_ActionButton {
  background: white;
  border: none;
  color: black;
  font-family: 'ChakraPetch', cursive;
  font-size: 1.4rem;
  padding: 10px;
  border-radius: 5px;
  margin-top: 40px;
  transition: 1s;
  max-width: 300px;
  cursor: pointer;
}

.Snapshot_ActionButton:hover {
  background: yellow;
  color: black;
}

.Snapshot_Details {
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .Snapshot_MainSection {
    width: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .Snapshot_Notice {
    max-width: 90%;
    line-height: 25px;
    font-size: 1.3rem;
  }

  .Snapshot_ActionButton {
    font-size: 1.2rem;
  }

  .Snapshot_Input {
    max-width: 90%;
    font-size: 1rem;
  }

  .Snapshot_Notice_Res {
    font-size: 1rem;
    max-width: 90%;
  }

  .Snapshot_Logo {
    height: 50px;
  }
}


@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-10px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}