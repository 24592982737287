.ApostleGrid_Entry {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    border: 1px solid black;
    margin: 5px;
}

.ApostleGrid_Entry_Selected {
    border: 2px solid white;
    filter: drop-shadow(5px 8px 12px black);
}

.ApostleGrid_Entry img {
    height: 100%;
    width: 100%;
}

.ApostleGrid_Entry p {
    color: white;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    width: 100%;
    margin: 0;
}

.ApostleGrid_Entry_Selected p {
    color: #9d00ff;
}

.ApostleGrid_Entry:hover {
    /* background-color: #9d00ff; */
}

.ApostleGrid_Entry_Used {
    filter: brightness(0.35);
}