.skylight-dialog {
  background: black !important;
  color: white !important;
}

.separator {
  height: 0.5px;
  background-color: white;
  width: 100%;
  margin: 0 auto;
}

.Modal_Title {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.Modal_Title p {
  margin-left: 20px;
  font-size: 1.2rem;
}

.skylight-close-button {
  top: 10px !important;
  right: 20px !important;
}

.Modal_Entries {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Modal_Entry {
  font-size: 1.25rem;
  display: flex;
  justify-content: space-between;
}

.Modal_Entry a {
  margin-left: 10px;
  color: blue;
}

.Modal_Actions {
  display: flex;
  justify-content: center;
}

.Modal_Actions button {
  background: white;
  position: relative;
  display: inline-block;
  overflow: hidden;
  border: none;
  padding: 15px 40px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: background-color 200ms linear, color 200ms linear;
  transition: background-color 200ms linear, color 200ms linear;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px;
  text-decoration: none;
  margin-top: 10px;
  cursor: pointer;
}

.Modal_Confirmation {
  background: green;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
  animation: flash 2.5s ease-in infinite;
}

.Modal_Status {
  text-align: left !important;
}

.Modal_Status span {
  color: #9d00ff;
  font-size: 0.9rem !important;
}
.Modal_Entries p {
  font-size: 0.85rem !important;
}

.Modal_Entry p {
  font-size: 0.85rem !important;
}

@media only screen and (max-width: 600px) {
  .skylight-close-button {
    font-size: 1.4rem !important;
  }

  .Modal_Status {
    font-size: 0.8rem !important;
  }

  .skylight-close-button {
    font-size: 1.4rem !important;
  }
  .Modal_Status span {
    color: #9d00ff;
    font-size: 0.8rem !important;
  }
  .Modal_Entries {
    flex-direction: column;
    justify-content: center;
  }
  .Modal_Entry {
    flex-direction: column;
    font-size: 0.8rem;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 100%;
  }
  .Modal_Entry p {
    margin: 0;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 0.4rem !important;
    word-wrap: break-word;
  }

  .Modal_Title p {
    font-size: 0.5rem !important;
  }

  .Modal_Status span {
    font-size: 0.5rem !important;
  }

  .Modal_Actions button {
    padding: 15px 20px;
  }

  .Modal_Actions {
    flex-direction: column;
  }
}
